$purple:#5C2695;
$blue:#337AB7;
$dark-purple:#402A75;
$light-purple:#987BB7;
$purple:#5C2695;
$dark-grey:#5B595C;
$light-grey:rgba(103,58,183,0.1);
$form-grey:#F9F9F9;
$grey:#EAEAEA;
$red:#b15555;
$black:#000000;
$sql-dark-grey: #A9A9A9;